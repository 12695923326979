import useTranslation from "@cospex/client/hooks/useTranslation";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

declare global {
  interface Window {
    FrontChat: (
      command: string,
      options: { chatId: string; useDefaultLauncher: boolean }
    ) => void;
  }
}

const FrontAppChat = () => {
  const location = useLocation();
  const pagesWithChat = ["/contact", "/unsubscribe"];
  const { i18n } = useTranslation();
  const chatScriptId = "frontapp-chat-script";
  const chatIframeId = "front-chat-iframe";

  useEffect(() => {
    const loadChatScript = () => {
      const script = document.createElement("script");
      script.id = chatScriptId;
      script.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js";
      script.onload = () => {
        window.FrontChat("init", {
          chatId: "e084b8bd5c67fca8fdf4af54a17687bc",
          useDefaultLauncher: true,
        });
      };
      document.body.appendChild(script);
    };

    const removeChatScript = () => {
      document.getElementById(chatScriptId)?.remove();
      document.getElementById(chatIframeId)?.remove();
    };

    if (pagesWithChat.includes(location.pathname) && i18n.language === "fr") {
      loadChatScript();
    } else {
      removeChatScript();
    }

    return removeChatScript;
  }, [location.pathname, i18n.language]);

  return null;
};

export default FrontAppChat;
