import { Box, Container, SxProps, Theme } from "@mui/material";

export default function Section({
  children,
  fullWidth,
  sx,
}: {
  children: React.ReactNode;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
}) {
  return (
    <Box
      component="section"
      sx={{
        py: {
          xs: 4,
          md: 12,
        },
        overflow: "hidden",
        ...sx,
      }}
    >
      <Container maxWidth={fullWidth ? false : "lg"}>{children}</Container>
    </Box>
  );
}
