import React, { createContext } from "react";

export interface IParcelConfig {
  carrierLogosImg: string;
  mainGraphicLogosImg?: string;
  mainGraphicMessageOffset?: string;
}

export const ParcelConfigContext = createContext<IParcelConfig | undefined>(
  undefined
);

export default function ParcelConfigProvider({
  children,
  value,
}: {
  value: IParcelConfig;
  children: React.ReactNode;
}) {
  return (
    <ParcelConfigContext.Provider value={value}>
      {children}
    </ParcelConfigContext.Provider>
  );
}
